import './utils/ajax';
import Form from '../../modules/core/forms/theme/js/Form';
import Utils from '../../modules/utils/utils-js/theme/js/utils/Utils';
import OpenInfo from '../../modules/utils/utils-js/theme/js/utils/OpenInfo';
import ObjectFit from '../../modules/utils/utils-js/theme/js/utils/ObjectFit';

declare let Slider;
declare let FB;

class Site {
    public static MOBILE_X  = 768;

    public static onPageCalendar(): void {
        $(() => {
            const $months = $('.vcalendar__month');

            $months.each((ix, el) => {
                const $month = $(el);
                const $mbody = $('.vcalendar__body', $month);
                const $mbar = $('.vcalendar__bar', $month);
                // const $switcher = $('button', $mbar);

                $mbar.on('click', () => {
                    $month.toggleClass('vcalendar__month--opened', !$mbody.is(':visible'));

                    $mbody.slideToggle();
                });
            });
        });
    }

    public static onPageOffer(): void {
        $(() => {
            const $tabs = $('.voffer__tabs li');
            const $texts = $('.voffer__text');

            const settabfunc = (ix: number, force: boolean) => {
                $tabs.removeClass('selected');
                $tabs.eq(ix).addClass('selected');

                const $sel = $texts.eq(ix);

                if (force) {
                    $texts.not($sel).hide();
                    $sel.show();
                } else {
                    $texts.not($sel).slideUp();
                    $sel.slideDown();
                }
            };

            $tabs.each((ix, el) => {
                const $tab = $(el);

                $tab.on('click', (e) => {
                    settabfunc(ix, false);
                });
            });

            settabfunc(0, true);
        });
    }

    public static onPageHome(): void {
        new Slider($('.mainslider'), {
        	fixed: true,
        	max: 1
        });
    }

    public static discover($parent?: JQuery): void {
        Utils.updateElements($parent);
    }

    private static updateFloat() {
        const top = $(window).scrollTop();
        const wh = $(window).height();
        const wx = $(window).width();

        const $body = $('body');
        const $header = $('.header');
        const $mainmenu = $('.mainmenu');
        const $menu = $('.menu--main');

        $body.toggleClass('body--float', top > 0);
        $body.toggleClass('body--no-float', top == 0);
        $body.toggleClass('body--float140', top > 140);
        // $body.toggleClass('body--float-100', top > wh);
        // $body.toggleClass('body--float-ac', top > wh - 215);

        $mainmenu.toggleClass('mainmenu--mobile', wx <= Site.MOBILE_X);
        $mainmenu.toggleClass('mainmenu--full', wx > Site.MOBILE_X);

        $menu.toggleClass('menu--mobile', wx <= Site.MOBILE_X);
        $menu.toggleClass('menu--full', wx > Site.MOBILE_X);

        // $header.toggleClass('header--mobile', top > 0 || wx <= this.MOBILE_X);
        // $header.toggleClass('header--full', top == 0 && wx > this.MOBILE_X);
    }

    private static windowOpen(url, name): void {
        var width = 575;
        var height = 300;

        var left = ($(window).width()  - width)  / 2;
        var top = ($(window).height() - height) / 2;

        var opts = 'status=1,width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;

        return (<any> window).open(url, name, opts);
    }

    private static initShares($parent?: JQuery): void {
        $('.js-share--facebook', $parent).click((e) => {
            e.preventDefault();

            FB.ui({
                method: 'share',
                href: window.location.href
            });
        });

        $('.js-share--twitter', $parent).click((e) => {
            e.preventDefault();

            this.windowOpen('https://twitter.com/share', 'twitter');
        });
    }

    private static initHtml() {
        var userLang = navigator.language;

        if (
            (userLang.includes('ru') || userLang.includes('uk'))
            && sessionStorage.getItem('language') !== 'ru'
            && !window.location.href.includes('/ru/')
        ) {
            sessionStorage.setItem('language', 'ru');
            window.location.href = '/ru/';
        }

        var $mainmenu=$('.mainmenu');

        $('.menubutton', $mainmenu).click(function(e) {
            e.preventDefault();

            $mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
            $('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
        });

        this.initShares();

        this.discover();
    }

    private static initRwd() {
        var $header = $('.header');
        $header.addClass('header--noanim');

        $(window).resize(Site.updateFloat);
        $(window).scroll(Site.updateFloat);

        Site.updateFloat();

        $header.removeClass('header--noanim');
    }

    public static evalJs(): void {
        (<any> window)._js = (h: () => void) => {
            h();
        };

        let js = (<any> window)._jslist;

        $.each(js, (k, v) => {
            v();
        });
    }

    public static init() {
        (<any> window).Site = Site;

        OpenInfo.initModule();
        ObjectFit.initModule();
        Form.init();

        $(document).ready(() => {
            Site.initHtml();
            Site.initRwd();
        });

        $(window).on('load', () => {
            $('body').addClass('body--loaded');

            setTimeout(() => {
                $('body').removeClass('body--loading');
                $('body').trigger('pageloaded');
            }, 1000);
        });
    }
}

Site.init();
