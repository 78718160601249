import SiteFocus from "./SiteFocus";
import SVGInject from "../../../../../media/svg/theme/js/SVGInject";

export default class Utils {
    public static scrollTo(top: number, force?: boolean, speed?: number): void {
        if (force) {
            $('html,body').scrollTop(top);
        } else {
            $('html,body').animate({
                scrollTop: top
            }, speed ? speed : 900);
        }
    }

    public static slide($el: JQuery, visible: boolean, forced?: boolean, complete?: () => void, duration?: number): void {
        if (forced) {
            if (visible) {
                $el.show();
            } else {
                $el.hide();
            }

            if (complete != null) {
                complete();
            }
        } else {
            if (visible) {
                $el.slideDown(duration, complete);
            } else {
                $el.slideUp(duration, complete);
            }
        }
    }

    public static updateElements($parent?: JQuery): void {
        let wx = $(window).width();

        $('.lightbox, .fancybox, a[rel="lightbox"]', $parent).each(function() {
            var $el=$(this);

            (<any>$el).fancybox({
                titlePosition: 'over',
                width: $el.data('lightbox-width'),
                height: $el.data('lightbox-height'),
                type: $el.hasClass('js-lightbox--iframe') ? 'iframe' : null
            });
        });

        SVGInject.discover($parent);

        $('.js-gotop', $parent).click((e) => {
            e.preventDefault();

            Utils.scrollTo(0, false);
        });

        $('.js-godown', $parent).click(function (e) {
            e.preventDefault();

            var $this = $(this);
            var $header = $('.js-header');

            setTimeout(() => {
                var name = $this.data('name');

                if (!name) {
                    name = $this.attr('href').substring(1);
                }

                var $dst = $('[name="' + name + '"]');

                var hh = $header.height();
                var top = $dst.offset().top;

                Utils.scrollTo(top - hh, false);
            }, 1);
        });

        var $body = $('body');

        var anims = ['zoom'];

        $('.js-anim', $parent).each(function() {
            var $this = $(this);

            if (!$body.hasClass('body--loaded') && SiteFocus.isOnFocus($this)) {
                return;
            }

            if ($body.hasClass('body--mobile') && $this.hasClass('js-anim--nomobile')) {
                return;
            }

            var i = Math.floor(anims.length * Math.random());
            var type = anims[i];

            var old_transform = $this.css('transform');
            var old_transition = $this.css('transition');
            var old_opacity = $this.css('opacity');

            $this.addClass('anim--initial anim--' + type);

            SiteFocus.onViewFocus($this, function() {
                if (!$this.hasClass('anim--inited')) {
                    $this.addClass('anim--inited');

                    var rr = Math.random() * 400;

                    setTimeout(() => {
                        $this.css({
                            'transform': old_transform,
                            'opacity': old_opacity
                        });
                    }, 200 + rr);

                    setTimeout(() => {
                        $this.css({
                            'transition': old_transition
                        });
                    }, 200 + 800 + rr);
                }
            });
        });

        $('.js-counterbox__value', $parent).each(function () {
            var $this = $(this);
            var val:number = parseInt($this.text());

            if ($.isNumeric(val)) {
                $this.text('0');

                var cur: number = null;
                SiteFocus.onViewFocus($this, () => {
                    if (cur != null) {
                        return;
                    }

                    cur = 0;

                    var timer = setInterval(() => {
                        if (cur > val) {
                            clearTimeout(timer);
                            timer = null;
                            cur = val;
                        }

                        $this.text(Math.ceil(cur));

                        cur += (val - cur) * 0.05;
                    }, 30);
                });
            }
        });
    }

    public static init(): void {
        $(() => {
            Utils.updateElements();
        });
    }
}
